<template>
  <v-container
    :key="pageKey"
    fluid
    style="
      background: rgba(255, 255, 255, 0.9);
      min-width: 100%;
      min-height: 95vh;
      padding: 10px 20px;
      position: relative;
    "
  >
    <h6
      @click="closePage"
      style="
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        position: absolute;
        padding-top: 2px;
        width: 90px;
        cursor: pointer;
      "
    >
      <v-icon style="position: relative; top: -1px">mdi-chevron-left</v-icon
      >kembali
    </h6>
    <div style="margin: 25px 0 65px 0">
      <div style="border-bottom: 1px solid rgba(0, 0, 0, 0.2)">
        <div
          class="d-flex justify-start align-start"
          style="position: relative"
        >
          <h4 class="fpb-title mb-2">DETAIL PAYMENT REQUEST</h4>
          <v-tooltip bottom color="grey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                x-small
                text
                icon
                color="grey darken-2"
                @click="loadPage"
                style="position: relative; top: 12px"
              >
                <v-icon small>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>refresh page</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <div class="actionButton" v-if="detail != null">
            <!-- Admin -->

            <div class="d-flex">
              <div v-if="getUserProfile.employee.id == detail.employee_id">
                <v-btn
                  v-if="detail.status == 0 && isEdit"
                  @click.prevent="saveChange"
                  type="button"
                  elevation="0"
                  color="primary"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :loading="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Save
                </v-btn>
                <v-btn
                  v-if="detail.status == 0 && !isEdit"
                  type="button"
                  elevation="0"
                  color="primary"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  @click.prevent="editDetail"
                  :loading="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Edit
                </v-btn>
                <v-btn
                  v-if="detail.status == 0 && !isEdit"
                  elevation="0"
                  color="green"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :loading="loading"
                  :disabled="loading"
                  small
                  :rounded="wWidth < 809"
                  @click.prevent="submitPayreq"
                >
                  Submit
                </v-btn>
                <v-btn
                  v-if="detail.status == 5 || detail.status === 6"
                  type="button"
                  elevation="0"
                  color="primary"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  @click.prevent="setToDraftPayreq"
                  :loading="loading"
                  :disabled="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Set to Draft
                </v-btn>

                <!-- <v-btn
                  v-if="detail.status == 0 && !isEdit"
                  type="button"
                  elevation="0"
                  color="pink"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :loading="loading"
                  :disabled="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Cancela
                </v-btn> -->
                <v-btn
                  v-if="isEdit"
                  type="button"
                  elevation="0"
                  color="pink"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  @click.prevent="isEdit = !isEdit"
                  :loading="loading"
                  :disabled="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Cancel Edit
                </v-btn>

                <v-btn
                  v-if="detail.status == 2"
                  type="button"
                  elevation="0"
                  color="success"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  @click.prevent="paidPayreq"
                  :loading="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Bayar
                </v-btn>
                <v-btn
                  v-if="
                    (detail.status == 3 ||
                      detail.status == 2 ||
                      detail.status == 0) &&
                    !isEdit
                  "
                  type="button"
                  elevation="0"
                  color="pink"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :loading="loading"
                  :disabled="loading"
                  small
                  :rounded="wWidth < 809"
                  @click="cancelPayreq"
                >
                  Cancel Payreq
                </v-btn>
              </div>
              <div v-if="isAllow">
                <div v-if="detail.status == 1">
                  <div
                    v-if="
                      detail.approver_users.find(
                        (e) => e != getUserProfile.employee.email
                      ) == undefined
                    "
                  >
                    <v-btn
                      type="button"
                      elevation="0"
                      color="green"
                      class="white--text font-weight-bold ma-1"
                      style="font-size: 12px"
                      :loading="loading"
                      :disabled="loading"
                      small
                      :rounded="wWidth < 809"
                      @click="confirmPayreq"
                    >
                      Confirm
                    </v-btn>
                    <v-btn
                      type="button"
                      elevation="0"
                      color="pink"
                      class="white--text font-weight-bold ma-1"
                      style="font-size: 12px"
                      :loading="loading"
                      :disabled="loading"
                      small
                      :rounded="wWidth < 809"
                      @click="rejectPayreq"
                    >
                      Reject
                    </v-btn>
                  </div>
                </div>
              </div>
              <!-- <div
                  v-if="
                    getUserProfile.level.find(({ id }) => id === '3') !==
                    undefined
                  "
                  class="d-flex"
                > -->
              <div v-if="detail.status == 3" class="d-flex">
                <v-btn
                  type="button"
                  elevation="0"
                  color="primary"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  @click.prevent="openFormPJCA"
                  :loading="loading"
                  :disabled="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Buat PJCA
                </v-btn>
                <v-btn
                  type="button"
                  elevation="0"
                  color="green"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  @click.prevent="donePayreq"
                  :loading="loading"
                  :disabled="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Done
                </v-btn>
              </div>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
      <v-row no-gutters v-if="detail != null">
        <v-col cols="4">
          <p style="font-size: 12px">
            Tgl. Pengajuan: {{ convertDate(detail.createdAt) }} |
            {{ detail.employee_name }}
          </p>
        </v-col>
        <!-- <v-col cols="4">
          <p style="font-size: 12px">
            Tgl. Diproses: {{ '01/10/2024 | Widya Yuliana' }}
          </p>
        </v-col> -->
        <v-col cols="8">
          <div class="d-flex justify-end">
            <p
              :style="`background-color: ${statusColor(detail.status)};
                color: white;
                font-weight: bold;
                margin: 5px 0 0 0;
                padding: 0 10px;
                border: 1px solid rgba(0, 0, 0, 0.6);
                width: 180px;
                text-align: center;`"
            >
              {{ statusName(detail.status) }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="detail != null" no-gutters style="margin-top: 40px">
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            No. Dokumen
          </p>

          <v-text-field
            :value="detail.document_no"
            dense
            readonly
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            No. FPB
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openDetailFpb(detail.purchase_request)"
                  style="cursor: pointer"
                  x-small
                  color="primary"
                >
                  mdi-open-in-new
                </v-icon>
              </template>
              <span>Lihat detail FPB</span>
            </v-tooltip>
          </p>

          <v-text-field
            :value="
              detail.purchase_request != null
                ? detail.purchase_request.request_no
                : '-'
            "
            dense
            readonly
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Perusahaan
          </p>

          <v-text-field
            :value="detail.company_name"
            dense
            readonly
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Department
          </p>

          <v-text-field
            :value="detail.department_name"
            dense
            readonly
            style="font-size: 12px; height: 45px"
          />
        </v-col>

        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Jenis Payment Request
          </p>
          <v-autocomplete
            v-model="detail.payreq_type"
            :items="dropdown.payreq_type"
            item-text="name"
            item-value="id"
            style="font-size: 12px; height: 45px"
            return-id
            dense
            :outlined="isEdit"
            :clearable="isEdit"
            :readonly="!isEdit"
          ></v-autocomplete>
          <!-- <v-text-field
            :value="payreqTypeName(detail.payreq_type)"
            dense
            readonly
            style="font-size: 12px; height: 45px"
          /> -->
        </v-col>
        <v-col cols="12" md="9" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Vendor
          </p>

          <v-text-field
            v-model="detail.vendor_name"
            :outlined="isEdit"
            :clearable="isEdit"
            :readonly="!isEdit"
            dense
            style="font-size: 12px; height: 45px"
          />
        </v-col>

        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Jenis Pembayaran
          </p>
          <v-autocomplete
            v-model="detail.payment_type"
            :items="dropdown.payment_type"
            item-text="name"
            item-value="id"
            style="font-size: 12px; height: 45px"
            return-id
            dense
            :outlined="isEdit"
            :clearable="isEdit"
            :readonly="!isEdit"
          ></v-autocomplete>
          <!-- <v-text-field
            :value="paymentTypeName(detail.payment_type)"
            dense
            readonly
            style="font-size: 12px; height: 45px"
          /> -->
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Nama Bank
          </p>

          <v-text-field
            v-model="detail.bank_name"
            :outlined="isEdit"
            :clearable="isEdit"
            :readonly="!isEdit"
            dense
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            No. Rekening
          </p>

          <v-text-field
            v-model="detail.account_number"
            :outlined="isEdit"
            :clearable="isEdit"
            :readonly="!isEdit"
            dense
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Atas Nama
          </p>

          <v-text-field
            v-model="detail.account_name"
            :outlined="isEdit"
            :clearable="isEdit"
            :readonly="!isEdit"
            dense
            style="font-size: 12px; height: 45px"
          />
        </v-col>

        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Sumber Dana
          </p>

          <v-text-field
            v-model="detail.source_of_funds"
            :outlined="isEdit"
            :clearable="isEdit"
            :readonly="!isEdit"
            dense
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="9" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Keterangan
          </p>

          <v-text-field
            v-model="detail.payment_desc"
            :outlined="isEdit"
            :clearable="isEdit"
            :readonly="!isEdit"
            dense
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Disc. Global (%)
          </p>
          <!-- :readonly="!isEdit || (detail.global_discount != null && detail.global_discount != 0)" -->
          <v-text-field
            v-model="detail.global_discount_percent"
            :outlined="isEdit"
            :readonly="!isEdit"
            @change="globalPercentWatcher"
            type="number"
            min="0"
            dense
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Disc. Global (nominal)
          </p>
          <!-- :readonly="!isEdit || (detail.global_discount_percent != null && detail.global_discount_percent != 0)" -->
          <v-text-field
            v-if="isEdit"
            v-model="detail.global_discount"
            :outlined="isEdit"
            @change="globalAmountWatcher"
            type="number"
            min="0"
            dense
            style="font-size: 12px; height: 45px"
          />
          <v-text-field
            v-else
            :value="price(detail.global_discount)"
            dense
            style="font-size: 12px; height: 45px"
          />
        </v-col>
      </v-row>
      <v-card v-if="detail != null" style="margin-top: 40px">
        <v-tabs
          v-model="tab"
          background-color="rgb(240,240,240)"
          slider-color="primary"
          color="primary"
        >
          <v-tab v-for="item in items" :key="item.tab">
            {{ item.tab }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in items"
            :key="item.tab"
            style="padding: 20px"
          >
            <div style="min-height: 60vh">
              <div v-if="item.tab == 'Detail Barang'">
                <v-data-table
                  v-model="selectedData"
                  :loading="loading"
                  height="30vh"
                  mobile-breakpoint="0"
                  :dense="wWidth > 808 ? true : false"
                  style="cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.2)"
                  :headers="payreqHeaders"
                  :items="detail.payment_request_detail"
                  :items-per-page="10"
                  @click:row="rowClick"
                  :search="searchItem"
                  :custom-filter="customSearch"
                  :options.sync="options"
                  :show-select="
                    getUserProfile.level.find(({ id }) => id === '41') ===
                      undefined && detail.status == 0
                  "
                  item-key="id"
                >
                  <template v-slot:top>
                    <div
                      style="
                        border: thin solid rgba(0, 0, 0, 0.12);
                        cursor: auto;
                        padding: 0 15px;
                        height: 75px;
                        display: flex;
                        overflow: hidden;
                      "
                    >
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          margin: 0 15px 0 5px;
                        "
                      >
                        <v-btn
                          v-if="detail.status == 0"
                          @click="openItemForm"
                          style="font-size: 10px"
                          elevation="2"
                          tilerounded="5px"
                          x-small
                          color="primary"
                          class="white--text"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" small class="">
                                mdi-plus
                              </v-icon>
                            </template>
                            <span>Tambah item</span>
                          </v-tooltip>
                        </v-btn>
                      </div>
                      <div style="width: 120px">
                        <v-select
                          :disabled="selectedData.length === 0"
                          label="Action"
                          style="
                            position: relative;
                            top: 15px;
                            margin: 0 15px 0 5px;
                            font-size: 12px;
                          "
                          v-model="actionValue"
                          :items="itemsAction"
                          item-text="name"
                          item-value="id"
                          outlined
                          return-id
                          dense
                          @change="actionSelect"
                        ></v-select>
                      </div>
                      <div
                        style="
                          width: 260px;
                          position: relative;
                          top: 15px;
                          margin: 0 15px 0 5px;
                          font-size: 12px;
                          height: 45px;
                        "
                      >
                        <v-text-field
                          v-model="searchItem"
                          label="Cari barang"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                      <v-spacer></v-spacer>
                      <div style="display: flex; align-items: center">
                        <v-btn
                          elevation="0"
                          color="transparent"
                          small
                          outlined
                          style="margin-right: 10px"
                          :loading="loading"
                          @click="generatePdfPayreq"
                        >
                          <v-icon
                            color="primary"
                            small
                            style="margin-right: 5px"
                            >mdi-magnify
                          </v-icon>
                          <a href="#" style="font-weight: bold">Lihat PDF</a>
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <!-- <template v-slot:[`item.no`]="{ index }">
                    <div style="width: 35px">
                      {{ index + 1 }}
                    </div>
                  </template> -->
                  <template v-slot:[`item.product_code`]="{ item }">
                    <div style="width: 95px; font-size: 12px">
                      {{ item.product_code }}
                    </div>
                  </template>
                  <template v-slot:[`item.product_name`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          style="
                            text-align: left;
                            width: 250px;
                            font-size: 12px;
                            padding: 0;
                          "
                        >
                          {{
                            `${
                              item.product_code !== null
                                ? '(' + item.product_code + ')'
                                : ''
                            } ${item.product_name}`
                          }}
                        </div>
                      </template>
                      <span>
                        {{
                          `${
                            item.product_code !== null
                              ? '(' + item.product_code + ')'
                              : ''
                          } ${item.product_name}`
                        }}</span
                      >
                    </v-tooltip>
                  </template>
                  <!-- <template v-slot:[`item.qty_demand`]="{ item }">
                    <div style="width: 65px; font-size: 12px">
                      {{ item.qty_demand }}
                    </div>
                  </template> -->
                  <!-- <template v-slot:[`item.qty_left`]="{ item }">
                    <div style="width: 65px; font-size: 12px">
                      {{ item.qty_left }}
                    </div>
                  </template> -->
                  <template v-slot:[`item.qty_request`]="{ item }">
                    <div style="font-size: 12px">
                      {{ item.qty_request }}
                    </div>
                  </template>
                  <template v-slot:[`item.qty_done`]="{ item }">
                    <div style="font-size: 12px">
                      {{ item.qty_done }}
                    </div>
                  </template>
                  <!-- <template v-slot:[`item.qty_left`]="{ item }">
                    <div style="font-size: 12px">
                      {{ item.qty_left }}
                    </div>
                  </template> -->
                  <template v-slot:[`item.uom`]="{ item }">
                    <div style="width: 45px; font-size: 12px">
                      {{ item.uom_name }}
                    </div>
                  </template>
                  <template v-slot:[`item.price_request`]="{ item }">
                    <div style="width: 105px; font-size: 12px">
                      {{ price(item.price_request) }}
                    </div>
                  </template>
                  <template v-slot:[`item.discount_percent`]="{ item }">
                    <div style="width: 75px; font-size: 12px">
                      {{ price(item.discount_percent) }}
                    </div>
                  </template>
                  <template v-slot:[`item.discount_amount`]="{ item }">
                    <div style="width: 105px; font-size: 12px">
                      {{ price(item.discount_amount) }}
                    </div>
                  </template>
                  <template v-slot:[`item.total_discount`]="{ item }">
                    <div style="width: 105px; font-size: 12px">
                      {{ price(item.total_discount) }}
                    </div>
                  </template>
                  <template v-slot:[`item.tax_type`]="{ item }">
                    <div style="width: 105px; font-size: 12px">
                      <v-chip
                        x-small
                        color="grey"
                        v-if="
                          dropdown.tax.find(({ id }) => id == item.tax_type) !=
                          undefined
                        "
                        style="font-size: 10px; font-weight: bold"
                      >
                        {{
                          dropdown.tax.find(({ id }) => id == item.tax_type)
                            .name
                        }}
                      </v-chip>
                      <span v-else></span>
                      <!-- <v-autocomplete
                        v-model="item.tax"
                        :items="dropdown.tax"
                        item-text="name"
                        item-value="id"
                        style="font-size: 12px; height: 45px"
                        return-id
                        dense
                        clearable
                        readonly
                        append-icon=""
                      ></v-autocomplete> -->
                    </div>
                  </template>
                  <template v-slot:[`item.subtotal`]="{ item }">
                    <div style="width: 105px; font-size: 12px">
                      {{ price(item.subtotal) }}
                    </div>
                  </template>
                </v-data-table>
                <div class="items-total-div-2 mt-5">
                  <v-card elevation="1" class="items-total-box">
                    <div style="font-size: 12px">
                      <p>Harga dasar <span>:</span></p>
                      <p style="text-align: right; width: 100%">
                        {{ price(detail.total_base_price) }}
                      </p>
                    </div>
                    <div style="font-size: 12px">
                      <p>Total Discount<span>:</span></p>
                      <p style="text-align: right; width: 100%">
                        {{ price(detail.total_discount) }}
                      </p>
                    </div>
                    <div style="font-size: 12px">
                      <p>Disc. Global (%)<span>:</span></p>
                      <p style="text-align: right; width: 100%">
                        {{ price(detail.global_discount_percent) }}
                      </p>
                    </div>
                    <div style="font-size: 12px">
                      <p>Disc. Global (nominal)<span>:</span></p>
                      <p style="text-align: right; width: 100%">
                        {{ price(detail.global_discount) }}
                      </p>
                    </div>
                    <div style="font-size: 12px">
                      <p>Nominal Sebelum Pajak <span>:</span></p>
                      <p style="text-align: right; width: 100%">
                        {{ price(detail.untaxed_amount) }}
                      </p>
                    </div>
                    <div style="font-size: 12px">
                      <p>Pajak <span>:</span></p>
                      <p style="text-align: right; width: 100%">
                        {{ price(detail.total_tax_amount) }}
                      </p>
                    </div>
                    <div style="font-size: 14px; font-weight: bold">
                      <p>TOTAL <span>:</span></p>
                      <p style="text-align: right; width: 100%">
                        {{ price(detail.total_price) }}
                      </p>
                    </div>
                  </v-card>
                </div>
              </div>

              <div v-if="item.tab == 'Approval'">
                <div style="margin-top: 20px">
                  <v-row no-gutters style="padding: 0">
                    <v-col md="12" style="padding: 0">
                      <!-- <div
                        style="
                          margin-left: 5px;
                          font-weight: bold;
                          margin-bottom: 10px;
                          border-bottom: 1px solid rgba(0, 0, 0, 0.4);
                        "
                      >
                        APPROVAL MATRIX
                      </div> -->
                      <v-data-table
                        mobile-breakpoint="0"
                        :headers="approvalHeaders"
                        :items="
                          detail.approval_matrix != null
                            ? detail.approval_matrix
                            : []
                        "
                        :items-per-page="10"
                        class="elevation-1 mx-auto"
                        hide-default-footer
                      >
                        <template v-slot:[`item.document_no`]="{ item }">
                          <div style="width: 140px; font-size: 12px">
                            {{ item.document_no }}
                          </div>
                        </template>
                        <template v-slot:[`item.table_name`]="{ item }">
                          <div style="width: 140px; font-size: 12px">
                            {{ item.table_name }}
                          </div>
                        </template>
                        <template v-slot:[`item.approver_user`]="{ item }">
                          <div style="cursor: pointer" v-if="item">
                            <v-chip
                              label
                              outlined
                              small
                              style="
                                margin-right: 5px;
                                padding: 0 3px;
                                cursor: pointer;
                                border: 1px solid rgba(0, 0, 0, 0.5);
                              "
                              v-for="(item, index) in item.approver_user"
                              :key="index"
                            >
                              {{ item.user !== null ? item.user.name : '-' }}
                            </v-chip>
                          </div>
                        </template>
                        <template v-slot:[`item.approved`]="{ item }">
                          <div
                            v-for="(items, index) in item.approver_user"
                            :key="index"
                            style="width: 140px"
                          >
                            <v-chip
                              v-if="items.status === 1"
                              label
                              outlined
                              small
                              style="
                                margin-right: 5px;
                                padding: 0 3px;
                                cursor: pointer;
                                border: 1px solid rgba(0, 0, 0, 0.5);
                              "
                            >
                              {{ item.user !== null ? items.user.name : '-' }}
                            </v-chip>
                          </div>
                        </template>
                        <template v-slot:[`item.rejected`]="{ item }">
                          <div
                            v-for="(items, index) in item.approver_user"
                            :key="index"
                            style="width: 140px"
                          >
                            <v-chip
                              v-if="items.status === -1"
                              label
                              outlined
                              small
                              style="
                                margin-right: 5px;
                                padding: 0 3px;
                                cursor: pointer;
                                border: 1px solid rgba(0, 0, 0, 0.5);
                              "
                            >
                              {{ item.user !== null ? items.user.name : '-' }}
                            </v-chip>
                          </div>
                        </template>
                        <template v-slot:[`item.created_at`]="{ item }">
                          <div style="width: 140px; font-size: 12px">
                            {{ item.created_at }}
                          </div>
                        </template>
                        <template v-slot:[`item.updated_at`]="{ item }">
                          <!-- <div style="width:140px; fontSize:12px;">
                  {{ item.approver_user[item.approver_user.length -1]. }}
                </div> -->
                          <div
                            v-for="(items, index) in item.approver_user"
                            :key="index"
                            style="width: 140px; font-size: 12px"
                          >
                            <span v-if="items.status === 1">
                              {{
                                items.updated_at !== null
                                  ? items.updated_at
                                  : '-'
                              }}
                            </span>
                            <br v-if="index > 0" />
                          </div>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <div
                    v-if="
                      getUserProfile.level.find(({ id }) => id === '41') ===
                      undefined
                    "
                  >
                    <div
                      style="
                        text-align: left;
                        padding-left: 5px;
                        margin-top: 5px;
                      "
                      v-if="detail.status === 1"
                    >
                      <v-btn
                        v-if="
                          getUserProfile.level.find(
                            ({ id }) => id === '1' || id === '26' || id === '30'
                          ) !== undefined
                        "
                        color="grey"
                        tile
                        dark
                        small
                        dense
                        style="font-size: 10px; width: 100px"
                        @click="fetch"
                        :loading="loading"
                      >
                        <span>Fetch Matrix</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <!-- <h4
                  v-else
                  class="d-flex justify-center align-center"
                  style="
                    color: grey;
                    font-weight: bold;
                    font-size: 16px;
                    margin-top: 25px;
                  "
                >
                  APPROVAL TIDAK DITEMUKAN
                </h4> -->
              </div>
              <div v-if="item.tab == 'PJCA'">
                <div style="margin-top: 20px">
                  <v-row no-gutters style="padding: 0">
                    <v-col md="12" style="padding: 0">
                      <v-data-table
                        mobile-breakpoint="0"
                        fixed-header
                        height="50vh"
                        :headers="pjcaHeaders"
                        style="cursor: pointer"
                        :items="pjcaResult"
                        :loading="loading"
                        @click:row="rowClickPjca"
                      >
                        <template v-slot:[`item.document_no`]="{ item }">
                          <div
                            :style="`min-width:150px; font-size:11px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                          >
                            {{ item.document_no }}
                          </div>
                        </template>
                        <template v-slot:[`item.payment_request`]="{ item }">
                          <div
                            :style="`width:150px; font-size:11px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                          >
                            {{
                              item.payment_request != null
                                ? item.payment_request.document_no
                                : ''
                            }}
                          </div>
                        </template>
                        <template v-slot:[`item.employee_name`]="{ item }">
                          <div
                            style="
                              width: 100px;
                              font-size: 11px;
                              padding: 0;
                              display: flex;
                              flex-direction: row;
                            "
                          >
                            {{ item.employee_name }}
                          </div>
                        </template>
                        <template v-slot:[`item.company_name`]="{ item }">
                          <div
                            :style="`width:150px; font-size:11px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                          >
                            {{ item.company_name }}
                          </div>
                        </template>
                        <template v-slot:[`item.department_name`]="{ item }">
                          <div
                            :style="`width:150px; font-size:11px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                          >
                            {{ item.department_name }}
                          </div>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                          <div
                            :style="`text-transform:uppercase; font-size:11px; font-weight:bolder; padding: 0; display: flex; flex-direction: row; color:${pjcaStatusColor(
                              item.status
                            )};`"
                          >
                            {{ pjcaStatusName(item.status) }}
                          </div>
                        </template>
                        <template v-slot:[`item.created_at`]="{ item }">
                          <div
                            style="
                              width: 120px;
                              font-size: 11px;
                              padding: 0;
                              display: flex;
                              flex-direction: row;
                            "
                          >
                            {{ convertDate(item.created_at) }}
                          </div>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>

        <div
          v-if="isEdit"
          style="
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.1);
          "
        ></div>
      </v-card>
    </div>
    <div>
      <FormItemPayreq
        :act="act"
        :detail="detail"
        :itemDetail="itemDetail"
        :proc="proc"
        @loadPage="loadPage"
      />
    </div>
    <div>
      <FormPjca :detail="detail" />
    </div>
    <v-snackbar
      top
      elevation="0"
      color="white"
      v-model="snackbar"
      :timeout="timeout"
    >
      <v-alert outlined type="error" prominent border="left">
        {{ alertText }}
      </v-alert>
    </v-snackbar>

    <v-dialog v-model="pdfDialog" width="90%" @click:outside="closePdf">
      <v-card style="position: relative; width: 100%; height: 90vh">
        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              @click.prevent="closePdf"
              v-bind="attrs"
              v-on="on"
              text
              icon
              color="white"
              style="
                position: fixed;
                top: 10px;
                right: 10px;
                z-index: 3;
                filter: drop-shadow(1px 1px 1px white);
              "
            >
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Close</span>
        </v-tooltip>
        <div
          id="loader"
          class="d-flex justify-center align-center"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        >
          <v-progress-circular
            v-if="isPdfOpen && selectedPdf !== null"
            :rotate="-90"
            :size="150"
            :width="15"
            :value="value"
            color="black"
          >
            {{ value / 10 }}%
          </v-progress-circular>
        </div>
        <div v-if="showPdf" style="height: 100%; padding: 5px 0">
          <v-tooltip bottom>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                @click.prevent="closePdf"
                v-bind="attrs"
                v-on="on"
                text
                icon
                outlined
                color="white"
                style="position: fixed; top: 10px; right: 10px; z-index: 3"
              >
                <v-icon color="black">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
          <vue-pdf-app
            v-if="selectedPdf !== null"
            @pages-rendered="pagesRendered"
            @open="openHandler"
            :pdf="selectedPdf"
            :file-name="detail.document_no"
          >
            <template #toolbar-sidebar-prepend="{ toggleTheme }">
              <button @click="toggleTheme = null" type="button">
                Toggle theme
              </button>
            </template>
          </vue-pdf-app>

          <div
            v-else
            class="d-flex justify-center align-center"
            style="
              height: 100%;
              width: 100%;
              margin-left: auto;
              position: relative;
              z-index: 1;
              background: #eaeaea;
            "
          >
            <h3 style="margin: 0">File Not Found</h3>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        background: rgba(0, 0, 0, 0.1);
      "
    >
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
        class="d-flex justify-center align-center"
      >
        <p
          class="d-flex justify-center align-center"
          style="font-size: 12px; margin: auto"
        >
          Please wait
        </p>
      </v-progress-circular>
    </div>
  </v-container>
</template>
<script>
import 'vue-pdf-app/dist/icons/main.css'
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapGetters, mapActions, mapMutations } from 'vuex'
const env = process.env.VUE_APP_BUILD_TYPE
// import moment from 'moment'
export default {
  components: {
    FormItemPayreq: () => import('./ItemForm'),
    FormPjca: () => import('../pjca/FormPjca.vue')
  },
  data() {
    return {
      pageKey: 0,
      config: require('../../../services/config.json')[env],
      wWidth: window.innerWidth,
      oAuth: buildType.apiURL('oauth'),
      proc: buildType.apiURL('proc'),
      upload: buildType.apiURL('upload2'),
      isLoadingPage: true,
      pagePath: this.$route.params.path,
      isPayreqPageEdit: false,
      userLevel: null,
      permission: null,
      isEdit: false,
      pdfDialog: false,
      dokumenDialog: false,
      closeDialog: false,

      checkbox: false,
      detail: null,
      searchItem: '',
      dokumenHeaders: [
        {
          text: 'File Name',
          value: 'subject',
          align: 'left',
          sortable: false,
          width: '90%'
        },
        {
          text: 'Action',
          value: 'action',
          align: 'center',
          sortable: false,
          width: '10%'
        }
      ],
      itemsAction: [],
      status: null,
      actionValue: 0,
      selectedData: [],
      loading: false,
      loader: null,
      fileNameRab: '',

      itemEdit: null,

      isAllow: false,
      matrix: [],
      rules: {},
      snackbar: false,
      alertText: '',
      timeout: 2000,
      value: 0,
      counter: 0,
      timer: 100,
      isPdfOpen: false,
      selectedPdf: null,
      showPdf: false,
      file_subject: '',

      selectedItem: null,
      options: {},
      tab: null,
      items: [
        { tab: 'Detail Barang', content: '' },
        { tab: 'Approval', content: '' },
        { tab: 'PJCA', content: '' }
      ],
      act: 'add',
      itemDetail: null,
      pjcaResult: [],
      dropdown: {
        payment_type: [
          {
            id: 1,
            name: 'Tunai'
          },
          {
            id: 2,
            name: 'Bank'
          },
          {
            id: 3,
            name: 'Giro'
          },
          {
            id: 4,
            name: 'Cek'
          },
          {
            id: 5,
            name: 'Transfer'
          }
        ],
        payreq_type: [
          {
            id: 1,
            name: 'CA'
          },
          {
            id: 2,
            name: 'Reimburst'
          },
          {
            id: 3,
            name: 'Pelunasan PO'
          },
          {
            id: 4,
            name: 'Lain-lain'
          }
        ],
        tax: [
          {
            id: 3,
            name: 'PPn 11% Inc'
          },
          {
            id: 4,
            name: 'PPn 11% Exc'
          },
          {
            id: 5,
            name: 'PPh 22 (0,3%)'
          }
        ],
        vendor: []
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getPayreqList', 'getTabs']),
    payreqHeaders() {
      const arrHeaders = [
        // {
        //   text: 'No',
        //   value: 'no',
        //   align: 'left'
        //   // sortable: true,
        // },
        {
          text: 'Kode Barang',
          value: 'product_code',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Nama Barang',
          value: 'product_name',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Qty',
          value: 'qty_request',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Qty Rilis',
          value: 'qty_done',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Unit',
          value: 'uom_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Disc (%)',
          value: 'discount_percent',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Disc Nominal',
          value: 'discount_amount',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Harga',
          value: 'price_request',
          align: 'left',
          sortable: false
        },
        {
          text: 'Total Discount',
          value: 'total_discount',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Pajak',
          value: 'tax_type',
          align: 'center',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Subtotal',
          value: 'subtotal',
          align: 'left',
          // sortable: true,
          sortable: false
        }
      ]
      // if (this.detail.status.id === 0) {
      return arrHeaders
      // }
    },
    approvalHeaders() {
      const arrHeaders = [
        { text: 'Sequence', value: 'sequence', align: 'left' },
        { text: 'Doc. No', value: 'document_no', align: 'left' },
        { text: 'Model', value: 'table_name', align: 'center' },
        { text: 'User', value: 'approver_user', align: 'center' },
        {
          text: 'Min. Approver',
          value: 'min_approver',
          align: 'center'
        },
        {
          text: 'Approved by',
          value: 'approved',
          align: 'center'
        },
        {
          text: 'Rejected by',
          value: 'rejected',
          align: 'center'
        },
        {
          text: 'Created at',
          value: 'created_at',
          align: 'center'
        },
        {
          text: 'Update at',
          value: 'updated_at',
          align: 'center'
        }
      ]
      return arrHeaders
    },
    pjcaHeaders() {
      const arrHeaders = [
        // {
        //   text: 'No',
        //   value: 'no',
        //   align: 'left',
        //   sortable: false
        // },
        {
          text: 'Status',
          value: 'status',
          sortable: false
        },
        {
          text: 'No. Dokumen',
          value: 'document_no',
          align: 'left',
          sortable: false
        },
        {
          text: 'No. Payreq',
          value: 'payment_request',
          align: 'left',
          sortable: false
        },
        {
          text: 'PIC',
          value: 'employee_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Perusahaan',
          value: 'company_name',
          sortable: false
        },
        {
          text: 'Dari Department',
          value: 'department_name',
          sortable: false
        },
        {
          text: 'Dibuat pada',
          value: 'created_at',
          align: 'left',
          sortable: false
        }
      ]
      return arrHeaders
    }
  },
  watch: {
    selectedData() {
      this.itemsAction = [
        { id: 0, name: '' },
        { id: 1, name: 'Delete' }
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.appLevel = this.config.application.ecatalogue.userLevel
    this.appLevel.administrator =
      this.config.application.all.userLevel.administrator
    this.getDataMenu()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapMutations([]),
    ...mapActions(['menu_access', 'getMenuId']),
    customSearch(value, search, item) {
      return Object.values(item).some(
        (v) => v && v.toString().toLowerCase().includes(search)
      )
    },
    showMsgDialog(
      pModalType,
      pStatusMsg,
      pBtnCancel,
      pInputType,
      pInputAtt,
      yesBtn,
      noBtn,
      pText
    ) {
      const title =
        pInputType === 'file'
          ? 'Upload bukti approvement pada kolom dibawah'
          : ''

      let additional = ''
      if (pText !== undefined) {
        additional = '<span style="font-size:12px;">' + pText + '</span>'
      }
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html:
              '<strong style="font-size:18px;">' +
              pStatusMsg +
              '</strong>' +
              '<br/>' +
              additional,
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            input: pInputType !== undefined ? pInputType : null,
            inputLabel: pInputType !== undefined ? title : null,
            inputAttributes: pInputAtt !== undefined ? pInputAtt : null,
            confirmButtonText: yesBtn || 'Yes',
            cancelButtonText: noBtn || 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    actionSelect(event) {
      switch (event) {
        case 1:
          this.itemDelete()
          break
      }
    },
    globalAmountWatcher() {
      if (this.detail != null) {
        if (
          this.detail.global_discount != null &&
          this.detail.global_discount != 0
        ) {
          this.detail.global_discount_percent = 0
        }
      }
      return 0
    },
    globalPercentWatcher() {
      if (this.detail != null) {
        if (
          this.detail.global_discount_percent != null &&
          this.detail.global_discount_percent != 0
        ) {
          this.detail.global_discount = 0
        }
      }
      return 0
    },
    async getDataMenu() {
      this.isLoadingPage = true
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      console.log('Path>>>>>', routeModule)
      await this.getMenuId({
        keyword: '',
        path: routeModule
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.all.applicationId ||
                application.id ===
                  this.config.application.ecatalogue.applicationId
            )
            if (this.userLevel !== null) {
              this.checkMenuAccess([menuId, this.userLevel.id])
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            this.initDataForLayout()
          }
        }
      }
      this.isLoadingPage = false
    },
    initDataForLayout() {
      this.isEdit = false

      this.loadPage()
    },
    nextPage() {
      this.loading = true
      try {
        const pageIndex = Number(this.$route.query.idx) + 1
        const path = `/e-catalogue/payreq/${this.getPayreqList.payreqList[pageIndex].id}?idx=${pageIndex}`
        if (this.$route.path !== path) this.$router.push(path)
        setTimeout(() => {
          this.loadPage()
        }, 200)
      } catch (e) {
        this.loading = false
      }
    },
    previousPage() {
      this.loading = true
      try {
        const pageIndex = Number(this.$route.query.idx) - 1
        const path = `/e-catalogue/payreq/${this.getPayreqList.payreqList[pageIndex].id}?idx=${pageIndex}`
        if (this.$route.path !== path) this.$router.push(path)
        setTimeout(() => {
          this.loadPage()
        }, 200)
      } catch (e) {
        this.loading = false
      }
    },
    async loadPage() {
      this.loading = true
      const url = `${this.proc}payreq/detail/${this.$route.params.id}`
      await axios
        .get(url)
        .then((res) => {
          console.log('Detaill >>>', res)
          if (res.data.status_code == '00') {
            this.detail = res.data.data
            if (res.data.data.status == 1) {
              this.authorityCheck(res.data.data)
            }
            // this.dropdownDepartment()
            // this.dropdownShift()
            this.getPjca()
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
          this.loading = false
          return null
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async saveChange() {
      this.showMsgDialog('question', 'Simpan Perubahan', true).then(
        async (res) => {
          if (res.isConfirmed) {
            this.loading = true
            const form = {
              act: 'update',
              id: this.$route.params.id,
              payment_type: this.detail.payment_type,
              account_name: this.detail.account_name,
              account_number: this.detail.account_number,
              bank_name: this.detail.bank_name,
              payreq_type: this.detail.payreq_type,
              payment_desc: this.detail.payment_desc,
              source_of_funds: this.detail.source_of_funds,
              // ppn: this.detail.ppn,
              // pph: this.detail.pph,
              global_discount: this.detail.global_discount,
              global_discount_percent: this.detail.global_discount_percent
            }

            await axios
              .post(`${this.proc}payreq/save`, form)
              .then((res) => {
                console.log(res.data)
                this.loading = false
                if (res.data.status_code == '00') {
                  this.loadPage()
                  this.showMsgDialog('success', res.data.status_msg, false)
                } else {
                  if (res.data.status_msg === 'Parameter value has problem') {
                    this.showMsgDialog(
                      'warning',
                      'Invalid input, Parameter value has problem',
                      false
                    )
                  } else {
                    this.showMsgDialog('warning', res.data.status_msg, false)
                  }
                }
                this.isEdit = false
              })
              .catch((err) => {
                console.log(err)
                this.loading = false
                this.isEdit = false
                this.showMsgDialog(
                  'error',
                  err
                    ? 'Something went wrong, Please contact an admin!'
                    : 'Something went wrong, Please contact an admin!',
                  false
                )
              })
          }
        }
      )
    },
    openDetailFpb(xData) {
      if (xData != null) {
        const arrItem = {
          list: []
        }
        this.$store.commit('setFpbList', arrItem)
        setTimeout(() => {
          window.open(`/e-catalogue/fpb-list/fpb/${xData.id}?idx=0`, '_blank')
          // this.$router.push(`/e-catalogue/fpb-list/fpb/${xData.id}?idx=0`)
        }, 200)
      }
    },
    openItemForm() {
      this.act = 'add'
      setTimeout(() => {
        document.getElementById('payreqItemForm').click()
      }, 300)
    },
    // initDropdown() {
    //   this.dropdownUnit()
    // },

    closePage() {
      this.detail = null
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      // this.$store.commit('setFpbId', null)
      // this.$router.push('/e-catalogue/' + this.$route.params.path)
      if (this.prevRoute !== undefined) {
        // if (this.prevRoute.path !== '/e-catalogue/cart') {
        // this.$router.push(this.prevRoute.path)
        // } else {
        this.$router.push(routeModule)
        // }
      } else {
        this.$router.push(routeModule)
      }
    },
    rowClick(pValue) {
      this.act = 'update'
      this.itemDetail = pValue
      setTimeout(() => {
        document.getElementById('payreqItemForm').click()
      }, 300)
    },
    editDetail() {
      this.isEdit = true
    },
    submitPayreq() {
      this.showMsgDialog(
        'warning',
        'Data tidak dapat dirubah bila sudah disubmit, lanjutkan ?',
        true,
        undefined,
        undefined,
        'Submit',
        'Batal',
        ''
      ).then(async (res) => {
        if (res.isConfirmed) {
          this.loading = true
          axios
            .post(`${this.proc}payreq/submit`, { id: this.detail.id })
            .then(async (res) => {
              this.loading = false
              if (res.data.status_code == '00') {
                this.loadPage()
                this.showMsgDialog('success', res.data.status_msg, false)
                // this.showMsgDialog('success', res.data.status_msg, false)
                if (this.detail.approval_matrix !== null) {
                  await this.fetch()
                  console.log('fetch done')
                } else {
                }
              } else {
                if (res.data.status_msg === 'Parameter value has problem') {
                  this.showMsgDialog(
                    'warning',
                    'Invalid input, Parameter value has problem',
                    false
                  )
                } else {
                  this.showMsgDialog('warning', res.data.status_msg, false)
                }
              }
            })
            .catch((err) => {
              this.loading = false
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    confirmPayreq() {
      this.showMsgDialog(
        'question',
        'Approve payment request ?',
        true,
        undefined,
        undefined,
        'Confirm',
        'Batal',
        ''
      ).then((res) => {
        if (res.isConfirmed) {
          this.loading = true
          // const document_id = this.detail.approval_matrix[0].document_id
          axios
            .post(`${this.proc}payreq/confirm`, {
              document_id: this.detail.id
            })
            .then((res) => {
              this.loading = false
              if (res.data.status_code == '00') {
                this.loadPage()
                this.showMsgDialog('success', res.data.status_msg, false)
              } else {
                if (res.data.status_msg === 'Parameter value has problem') {
                  this.showMsgDialog(
                    'warning',
                    'Invalid input, Parameter value has problem',
                    false
                  )
                } else {
                  this.showMsgDialog('warning', res.data.status_msg, false)
                }
              }
            })
            .catch((err) => {
              this.loading = false
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    rejectPayreq() {
      this.showMsgDialog(
        'question',
        'Reject payment request ?',
        true,
        undefined,
        undefined,
        'Reject',
        'Batal',
        ''
      ).then((res) => {
        if (res.isConfirmed) {
          this.loading = true
          // const document_id = this.detail.approval_matrix[0].document_id
          axios
            .post(`${this.proc}payreq/reject`, {
              document_id: this.detail.id
            })
            .then((res) => {
              this.loading = false

              if (res.data.status_code == '00') {
                this.loadPage()
                this.showMsgDialog('success', res.data.status_msg, false)
              } else {
                if (res.data.status_msg === 'Parameter value has problem') {
                  this.showMsgDialog(
                    'warning',
                    'Invalid input, Parameter value has problem',
                    false
                  )
                } else {
                  this.showMsgDialog('warning', res.data.status_msg, false)
                }
              }
            })
            .catch((err) => {
              this.loading = false
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    cancelPayreq() {
      this.showMsgDialog(
        'question',
        'Cancel payment request ?',
        true,
        undefined,
        undefined,
        'Cancel',
        'Batal',
        ''
      ).then((res) => {
        if (res.isConfirmed) {
          this.loading = true
          // const document_id = this.detail.approval_matrix[0].document_id
          axios
            .post(`${this.proc}payreq/cancel`, {
              id: this.detail.id
            })
            .then((res) => {
              this.loading = false

              if (res.data.status_code == '00') {
                this.loadPage()
                this.showMsgDialog('success', res.data.status_msg, false)
              } else {
                if (res.data.status_msg === 'Parameter value has problem') {
                  this.showMsgDialog(
                    'warning',
                    'Invalid input, Parameter value has problem',
                    false
                  )
                } else {
                  this.showMsgDialog('warning', res.data.status_msg, false)
                }
              }
            })
            .catch((err) => {
              this.loading = false
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    setToDraftPayreq() {
      this.showMsgDialog(
        'question',
        'Set to draft payment request ?',
        true,
        undefined,
        undefined,
        'Simpan',
        'Batal',
        ''
      ).then((res) => {
        if (res.isConfirmed) {
          this.loading = true
          // const document_id = this.detail.approval_matrix[0].document_id
          axios
            .post(`${this.proc}payreq/set_to_draft`, {
              id: this.detail.id
            })
            .then((res) => {
              this.loading = false

              if (res.data.status_code == '00') {
                this.loadPage()
                this.showMsgDialog('success', res.data.status_msg, false)
              } else {
                if (res.data.status_msg === 'Parameter value has problem') {
                  this.showMsgDialog(
                    'warning',
                    'Invalid input, Parameter value has problem',
                    false
                  )
                } else {
                  this.showMsgDialog('warning', res.data.status_msg, false)
                }
              }
            })
            .catch((err) => {
              this.loading = false
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    paidPayreq() {
      this.showMsgDialog(
        'question',
        'Paid payment request ?',
        true,
        undefined,
        undefined,
        'Paid',
        'Batal',
        ''
      ).then((res) => {
        if (res.isConfirmed) {
          this.loading = true
          // const document_id = this.detail.approval_matrix[0].document_id
          axios
            .post(`${this.proc}payreq/paid`, {
              id: this.detail.id
            })
            .then((res) => {
              this.loading = false

              if (res.data.status_code == '00') {
                this.loadPage()
                this.showMsgDialog('success', res.data.status_msg, false)
              } else {
                if (res.data.status_msg === 'Parameter value has problem') {
                  this.showMsgDialog(
                    'warning',
                    'Invalid input, Parameter value has problem',
                    false
                  )
                } else {
                  this.showMsgDialog('warning', res.data.status_msg, false)
                }
              }
            })
            .catch((err) => {
              this.loading = false
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    donePayreq() {
      this.showMsgDialog(
        'question',
        'Done payment request ?',
        true,
        undefined,
        undefined,
        'Done',
        'Batal',
        ''
      ).then((res) => {
        if (res.isConfirmed) {
          this.loading = true
          // const document_id = this.detail.approval_matrix[0].document_id
          axios
            .post(`${this.proc}payreq/done`, {
              id: this.detail.id
            })
            .then((res) => {
              this.loading = false

              if (res.data.status_code == '00') {
                this.loadPage()
                this.showMsgDialog('success', res.data.status_msg, false)
              } else {
                if (res.data.status_msg === 'Parameter value has problem') {
                  this.showMsgDialog(
                    'warning',
                    'Invalid input, Parameter value has problem',
                    false
                  )
                } else {
                  this.showMsgDialog('warning', res.data.status_msg, false)
                }
              }
            })
            .catch((err) => {
              this.loading = false
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    openFormPJCA() {
      setTimeout(() => {
        document.getElementById('pjcaForm').click()
      }, 200)
    },
    itemDelete(item, index) {
      console.log('this.selectedData', this.selectedData)
      this.showMsgDialog(
        'question',
        'Item tersebut akan dihapus, Anda yakin ?',
        true
      ).then((res) => {
        if (res.isConfirmed) {
          for (let i = 0; i < this.selectedData.length; i++) {
            axios
              .delete(
                `${this.proc}payreq/delete_detail/${this.selectedData[i].id}`
              )
              .then((res) => {
                console.log('delete>>>>', res)
                if (i === this.selectedData.length - 1) {
                  if (res.data.status_code === '00') {
                    this.showMsgDialog('success', res.data.status_msg, false)
                  } else {
                    this.showMsgDialog('error', res.data.status_msg, false)
                  }
                  setTimeout(() => {
                    this.actionValue = 0
                    this.loadPage()
                  }, 200)
                }
              })
              .catch((err) => {
                if (i === this.selectedData.length - 1) {
                  this.showMsgDialog('error', err.message, false)
                  setTimeout(() => {
                    this.actionValue = 0
                  }, 200)
                }
              })
          }
        } else {
          this.actionValue = 0
        }
      })
    },
    // actionSelect(event) {
    //   switch (event) {
    //     case 1:
    //       // this.initDropdown()
    //       // this.itemEdit = this.selectedData[0]
    //       // setTimeout(() => {
    //       //   document.getElementById('fpbFormEdit').click()
    //       // }, 500)
    //       break
    //     case 2:
    //       this.delete()
    //       break
    //     case 4:
    //       this.setToDraftItem()
    //       break
    //   }
    // },
    price(pItem) {
      if (pItem != undefined) {
        if (pItem != null) {
          return pItem.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
        }
      }
      return 0
    },
    // percentConvert(detail) {
    //   const realization = detail.total_realization
    //   const totalPrice = detail.total_price
    //   let percent = 0
    //   if (realization != 0 && totalPrice != 0) {
    //     percent = (realization / totalPrice) * 100
    //   }
    //   return percent
    // },

    authorityCheck(item) {
      if (item.approval_matrix !== null) {
        const matrixId = item.approval_matrix[0].document_id
        axios
          .get(
            `${this.oAuth}approval_matrix/document/allow_user?document_id=${matrixId}&application_id=8`
          )
          .then((res) => {
            console.log('authorityCheck', res)

            if (res.data.status_code !== '-99') {
              if (res.data.is_allow_approve !== 0) {
                this.isAllow = true
              } else {
                this.isAllow = false
              }
            } else {
              this.isAllow = false
            }
          })
          .catch((err) => {
            console.log('Cek Otoritas error, Silahkan info ke Admin', err)
            // this.showMsgDialog(
            //   'error',
            //   err
            //     ? 'Cek Otoritas error, Silahkan info ke Admin'
            //     : 'Cek Otoritas error, Silahkan info ke Admin',
            //   false
            // )
          })
      }
    },
    async fetch() {
      this.loading = true
      const form = {
        id: this.detail.id
      }
      await axios
        .post(`${this.proc}payreq/fetch_matrix`, form)
        .then(async (res) => {
          if (res.data.status_code == '00') {
            this.loadPage()
          }
          this.loading = false
          this.showMsgDialog(
            res.data.status_code == '00' ? 'success' : 'warning',
            res.data.status_msg,
            false
          )
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },

    async getPjca() {
      const url = `${this.proc}pjca/list?payment_request_id=${this.detail.id}&offset=0&limit=1000&order_by=id&order_type=DESC`

      await axios
        .get(url)
        .then((res) => {
          console.log('list pjca >>>>', res)
          if (res.data.status_code === '00') {
            this.pjcaResult = res.data.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    rowClickPjca(pValue, pSlot) {
      setTimeout(() => {
        this.$router.push(
          `/e-catalogue/pjca/detail/${pValue.id}?idx=${pSlot.index}`
        )
      }, 200)
    },
    paymentTypeName(val) {
      const arrType = ['Tunai', 'Bank', 'Giro', 'Cek', 'Transfer']
      return arrType[val - 1]
    },
    payreqTypeName(val) {
      const arrType = ['CA', 'Reimburs', 'Pelunasan PO', 'Lain-lain']
      return arrType[val - 1]
    },
    statusName(val) {
      const arrType = [
        'DRAFT',
        'WAITING APPROVAL',
        'IN PROGRESS',
        'PAID',
        'DONE',
        'CANCEL',
        'REJECT'
      ]
      return arrType[val]
    },
    statusColor(val) {
      const arrType = [
        'grey',
        'orange',
        'green',
        'green',
        'green',
        'red',
        'red'
      ]
      return arrType[val]
    },

    pjcaStatusName(val) {
      const arrType = [
        'DRAFT',
        'WAITING APPROVAL',
        'IN PROGRESS',
        'DONE',
        'CANCEL',
        'REJECT'
      ]
      return arrType[val]
    },
    pjcaStatusColor(val) {
      const arrType = ['grey', 'orange', 'green', 'red', 'red']
      return arrType[val]
    },
    convertDate(val) {
      const raw = val
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      }
    },

    generatePdfPayreq() {
      this.loader = 'loading'

      const l = this.loader
      this[l] = !this[l]
      setTimeout(() => {
        this[l] = false
        axios({
          url: `${this.proc}payreq/export_to_pdf/${this.$route.params.id}`,
          method: 'GET',
          responseType: 'arraybuffer'
        })
          .then((res) => {
            this.selectedPdf = res.data
            setTimeout(() => {
              this.viewPDF()
            }, 1000)
          })
          .catch((error) => {
            console.log(error)
          })
      }, 2000)

      this.loader = null
    },
    viewPDF() {
      if (!this.isPdfOpen) {
        this.pdfDialog = true
        this.isPdfOpen = true
        // this.selectedPdf = 'true'
        // this.selectedPdf = pItem.file
        this.countDownTimer()
      }
    },
    countDownTimer() {
      if (this.counter < this.timer) {
        setTimeout(() => {
          this.counter += 1
          this.value += 10
          this.countDownTimer()
        }, 1)
      } else {
        this.showPdf = true
        this.value = 0
        this.counter = 0
      }
    },
    async openHandler(pdfApp) {
      this.info = []
      const info = await pdfApp.pdfDocument
        .getMetadata()
        .catch(console.error.bind(console))

      if (!info) return
      const props = Object.keys(info.info)
      props.forEach((prop) => {
        const obj = {
          name: prop,
          value: info.info[prop]
        }
        this.info.push(obj)
      })
    },
    pagesRendered(pdfApp) {
      setTimeout(() => (pdfApp.pdfViewer.currentScaleValue = 'page-actual'))
    },
    closePdf() {
      this.pdfDialog = false
      this.isPdfOpen = false
      this.showPdf = false
    }
  }
}
</script>

<style lang="scss" scoped>
.fpb-title {
  position: relative;
  top: 10px;
  margin: 0;
  // width: 50%;
  font-weight: bold;
  // text-align: center;
  font-size: 20px;
}
.items-total-div-2 {
  // padding: 10px 0;
  width: '40%';
  .items-total-box {
    border: 1px solid #e0e0e0;
    padding: 20px 10px;
    width: 400px;
    display: flex;
    margin-left: auto;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    font-size: 14px;
    div {
      border-bottom: 1px solid #e0e0e0;
      width: 100%;
      padding-top: 20px;
      display: flex;
      p {
        margin: 0;
        margin-right: 10px;
        min-width: 160px;
        position: relative;
        span {
          position: absolute;
          right: 0;
        }
      }
    }
  }
}
.items-total-div-3 {
  padding: 0 10px;
  width: 60%;
  font-size: 14px;
}
.actionButton {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.fpb-status-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.category-line {
  margin: 10px 0 10px 0;
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  line-height: 75px;
}
@media (max-width: 1200px) {
  .category-line-item {
    margin-bottom: 10px;
  }
}
@media (max-width: 808px) {
  .actionButton {
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 2;
    padding: 10px 0;
  }
  .items-total-div-2 {
    width: '100%';
    .items-total-box {
      width: 100%;
    }
  }

  .items-total-div-3 {
    margin-top: 10px;
    width: 100%;
  }
  .tipe-proses {
    margin-top: 5px;
  }
}

@media (max-width: 840px) {
  .fpb-status-section {
    // flex-direction: column;
    flex-wrap: wrap;
  }
}
@media (max-width: 780px) {
  .fpb-title {
    font-size: 3vw;
  }
}
</style>
